<template>
  <div class="static service">
	<div class="logo-left">
		<a href="https://www.cambro.com/storesafe" target="_parent">
			<img src="@/assets/images/storesafe-logo.png" width="100%" alt="StoreSafe logo" />
		</a>
	</div>
	<h1>Service</h1>
  </div>

  	<div class="static-grey">
	  When preparing food for service, it’s important to control risk factors such as poor personal hygiene, holding at incorrect temperatures, and cross-contamination. Insulated Transport and Holding solutions should assist with maintaining correct internal temperature of food based on your service needs, as well as securing contents in catering applications.
	</div>
	<div class="static-white">
	  <div class="static-split">
		  <div class="split-image">
			<img src="@/assets/images/service_pro-cart-ultra.jpg" alt="Pro Cart Ultra">
		  </div>
		  <div class="split-content">
			<h2>Pro Cart Ultra</h2>
			<p>The Pro Cart Ultra provides the ability to hold both hot and cold food in the same cart, at the same time. Plug the unit in for 4+ hours of food-safe holding time with frequent opening and closing.</p>
			<a href="https://www.cambro.com/Products/insulated-food-transport/electric-food-transport-carts/the-pro-cart-ultra-pan-carriers/" target="_blank" class="learn-more">
			<button @mouseover="isHovered = true" 
					@mouseleave="isHovered = false"
					:class="{ 'hovered': isHovered }">
			  Learn More
			</button></a>
		  </div>
	   </div>
	   
	   <div class="static-testimonial">
		   <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		   <div class="testimonial-quote">The way Pro Cart Ultra units are designed, to me, is just like having the Cadillac of hot boxes.&quot;</div>
		   <div class="testimonial-name"><a href="https://blog.cambro.com/2023/11/08/why-cambro-hot-boxes-are-unmatched/" target="_blank">Justin Webb, Food Operations Director at Central Virginia Correctional Unit #13</a></div>
	   </div>
	   
	  <div class="static-split red-line">
		  <div class="split-image">
			<img src="@/assets/images/service_ultra-pan-carrier.jpg" alt="Ultra Pan Carrier">
		  </div>
		  <div class="split-content">
			<h2>Ultra Pan Carrier</h2>
			<p>A non-electric, passive holding solution, keep food at safe temperature for 4 hours with minimal opening and closing.</p>
			<a href="https://www.cambro.com/Products/insulated-food-transport/insulated-front-loading-food-pan-carriers/upc300/" target="_blank" class="learn-more">
			<button @mouseover="isHovered = true" 
					@mouseleave="isHovered = false"
					:class="{ 'hovered': isHovered }">
			  Learn More
			</button></a>
		  </div>
	   </div>
	   
	   <div class="static-testimonial">
		  <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		  <div class="testimonial-quote">In addition to our delicious and guaranteed fresh food, we use insulated Cambro Hot Boxes to keep the entire feast hot for hours! Eliminating the need to rush cooking times, the Cambro is an essential tool for putting on the perfect clambake.&quot;</div>
		  <div class="testimonial-name"><a href="https://blog.cambro.com/2023/11/08/why-cambro-hot-boxes-are-unmatched/" target="_blank">Joe Feran, Owner of Lobster Brothers Seafood</a></div>
	  </div>
	  
	 <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/service_gobox.jpg" alt="GoBox">
		 </div>
		 <div class="split-content">
		   <h2>GoBox</h2>
		   <p>Cater compact with Cambro’s most lightweight insulated carrier for hot or cold food, designed to give you 4 hours of safe food holding.</p>
		   <a href="https://www.cambro.com/Products/insulated-food-transport/cam-gobox--food-transporters/cam-gobox-accessories/temperature-maintenance-tools/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
	  </div>
	  
	  <div class="static-testimonial">
		<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		<div class="testimonial-quote">We use a lot of Cambro products… The most used are the lightweight Hot Boxes; we use them for cold travel and hot travel. They work great for us for offsite events because we will use them as cold boxes on the way there for storage. Once we are at the event, we cook the products and put them back into the boxes as hot boxes and hold the food hot for the event. We have about 20 of these boxes and we wouldn’t be able to do what we do without them.&quot;</div>
		<div class="testimonial-name"><a href="https://blog.cambro.com/2024/03/20/cambros-business-of-the-week-831-catering/" target="_blank">831 Catering</a></div>
	  </div>
		
	   <div class="static-split red-line">
		   <div class="split-image">
			 <img src="@/assets/images/service_camchiller.jpg" alt="CamChiller & CamWarmer">
		   </div>
		   <div class="split-content">
			 <h2>Passive Holding Accessories</h2>
			 <p>Pair any passive holding unit with a CamChiller or Camwarmer to extend and maximize food-safe temperature holding. Thermobarriers can be added to create separate compartments for hot and cold foods or minimize empty space to discourage food temperature loss within a partially loaded carrier.</p>
			 <a href="https://www.cambro.com/Products/insulated-food-transport/cam-gobox--food-transporters/cam-gobox-accessories/temperature-maintenance-tools/" target="_blank" class="learn-more">
			 <button @mouseover="isHovered = true" 
					 @mouseleave="isHovered = false"
					 :class="{ 'hovered': isHovered }">
			   Learn More
			 </button></a>
		   </div>
		</div>

	  <div class="static-testimonial">
		<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		<div class="testimonial-quote">When being asked which Cambro product helps make her business work, GoBoxes with the Camchiller and Camdolly! Transporting sheet trays is so easy and everything stays super cold with the chiller. Comes with me to almost every event.&quot;</div>
		<div class="testimonial-name"><a href="https://blog.cambro.com/2023/08/04/cambros-business-of-the-week-rgkkitchen/" target="_blank">Rae Gabler-Kirbos, Co-Founder of RGKkitchen</a></div>
	  </div>
		
	   <div class="static-split red-line">
		   <div class="split-image">
			 <img src="@/assets/images/service_gobags.jpg" alt="GoBags / Pizza Bags">
		   </div>
		   <div class="split-content">
			 <h2>GoBags / Pizza Bags</h2>
			 <p>GoBags offer high density insulation to protect food temperature and quality from kitchen to table, taking the guesswork out of delivery end quality.</p>
			 <a href="https://www.cambro.com/Products/insulated-food-transport/gobags/" target="_blank" class="learn-more">
			 <button @mouseover="isHovered = true" 
					 @mouseleave="isHovered = false"
					 :class="{ 'hovered': isHovered }">
			   Learn More
			 </button></a>
		   </div>
		</div>
	   
	   <div class="static-testimonial">
		  <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		  <div class="testimonial-quote">We did a catering order with 10 pizzas. We loaded up those 5-pizza GoBags, drove them over to the catering spot, and I just noticed how much hotter and better the pizza was.&quot;</div>
		  <div class="testimonial-pic"><img src="@/assets/images/prep_testimonial-1.png" /></div>
		  <div class="testimonial-name"><a href="https://blog.cambro.com/2023/01/25/righteous-slice/" target="_blank">Bill Crawford, Founder of Righteous Slice</a></div>
	  </div>
	  
	  <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/service_griplid.jpg" alt="GripLids">
		 </div>
		 <div class="split-content">
		   <h2>GripLid</h2>
		   <p>Unbeatable protection against spills and cross contamination, the Camwear Grip Lid is designed with a molded-in gasket to grip your food pan full of product tightly!</p>
		   <a href="https://www.cambro.com/Products/food-pans-and-lids/food-pans/camwear-griplids/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
	    </div>
		 
		 <div class="static-testimonial">
			<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
			<div class="testimonial-quote">Lately, we started using the Camwear GripLids with the Camwear Pans and it’s allowed us to not worry about any product spillage or cross-contamination because the lids seal nice and tight.&quot;</div>
			<div class="testimonial-pic"><img src="@/assets/images/service_testimonial-1.jpg" /></div>
			<div class="testimonial-name"><a href="https://blog.cambro.com/2021/10/25/cambros-business-of-the-week-coronado-kitchen/" target="_blank">Tyler Tragemann, Chef and Owner of Coronado Kitchen</a></div>
		</div>
		
 	   <!-- <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/service_meal-delivery-carts.jpg" alt="Meal Delivery Carts">
		 </div>
		 <div class="split-content">
		   <h2>Meal Delivery Carts</h2>
		   <p>Designed for healthcare operations, the Meal Delivery Cart is manufactured with thick CFC-free foam insulation to preserve optimal meal temperatures, and is rust, dent, crack, chip, and break resistant to protect the contents on your trays.</p>
		   <a href="https://www.cambro.com/Products/insulated-food-transport/meal-delivery-carts/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
		</div> -->
		 
		 <!-- <div class="static-testimonial">
			<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
			<div class="testimonial-quote">We looked at the Cambro carts and we decided to go with [the 10 Tray Meal Delivery Cart] because they were more durable. They weren’t like stainless steel where constantly we had to make sure they’re shining. They’re easy to clean and maintain, a lot easier than the bigger, bulky trucks. Pushing them around in the hallway is easier on my staff, it’s not a strain on their backs or anything.&quot;</div>
			<div class="testimonial-name"><a href="https://blog.cambro.com/2022/05/09/your-hospital-will-want-cambro-meal-delivery-carts-stat/" target="_blank">Nick McElroy, Food Service Manager at South Shore University Hospital</a></div>
		</div> -->
		
		<!-- <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/service_versa-food-bar.jpg" alt="Versa Food Bar">
		 </div>
		 <div class="split-content">
		   <h2>Versa Food Bar</h2>
		   <p>Maximize space, improve traffic, or add merchandising opportunities with this non-electric mobile vending solution, holding cold Grab n’ Go items such as salads, sandwiches, beverages, fruits and vegetables, and snacks safely for 4+ hours.</p>
		   <a href="https://www.cambro.com/Products/food-and-salad-bars/food-and-salad-bars/versa-food-bar/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
		</div> -->
		 
		 <!-- <div class="static-testimonial">
			<div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
			<div class="testimonial-quote">In the morning, these carts are filled with different breakfast items and wheeled to separate halls. We are able to feed all the children in about 15 minutes.&quot;</div>
			<div class="testimonial-name"><a href="https://blog.cambro.com/2021/10/12/lunch-on-the-go-thinking-outside-the-cafeteria/" target="_blank">Carolyn Barnes, Foodservice Director of Newberry County School District</a></div>
		</div> -->
		
		<!-- <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/service_coldfest.jpg" alt="Coldfest Containers">
		 </div>
		 <div class="split-content">
		   <h2>Coldfest Containers</h2>
		   <p>Keep ingredients, condiments, and creamers chilled at food-safe temperature during self-service and caterings, without ice.</p>
		   <a href="https://www.cambro.com/Products/food-storage/coldfest-containers/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
		</div> -->
		
		<div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/service_serving-spoons.jpg" alt="Serving Spoons, Tongs, and Ladles">
		 </div>
		 <div class="split-content">
		   <h2>Serving Spoons, Tongs, and Ladles</h2>
		   <p>These utensils are made from break and heat-resistant materials to safely handle your food. Not only are they designed for ergonomic handling, but food-safe air drying and storing. Personalization is available on all ladle handles to label self-serve ingredients.</p>
		   <a href="https://www.cambro.com/Products/display-and-serving/ladels-spoons-and-tongs/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
		</div>
		
		<div class="sub-white">
			<h1>Other Resources</h1>
			<div class="home-categories">
				<a href="https://cambro.com/storesafe/receiving" target="_parent" class="home-cat"><div class="cat-receiving"></div></a>
				<a href="https://cambro.com/storesafe/storage" target="_parent" class="home-cat"><div class="cat-storage"></div></a>
				<a href="https://cambro.com/storesafe/preparation" target="_parent" class="home-cat"><div class="cat-prep"></div></a>
				<a href="#" class="home-cat dimmed"><div class="cat-service"></div></a>
				<a href="https://cambro.com/storesafe/warewashing" target="_parent" class="home-cat"><div class="cat-warewashing"></div></a>
			</div>
		</div>
	   
	</div>
  
  
  </template>
  
