<template>
  <div class="static receiving">
	<div class="logo-left">
		<a href="https://www.cambro.com/storesafe" target="_parent">
			<img src="@/assets/images/storesafe-logo.png" width="100%" alt="StoreSafe logo" />
		</a>
	</div>
	<h1>Receiving</h1>
  </div>

  <div class="static-grey">
	Food is at risk as soon as you receive it. Risk factors such as damaged packaging, unsafe temperatures, cross-contamination during inspection, and poor personal hygiene of delivery personnel are all contributing factors to a foodborne illness outbreak. For these reasons, it’s important to have the equipment & supplies in place to promote organization and efficiency when inspecting food deliveries and transporting product into proper storage.
  </div>
  <div class="static-white">
    <div class="static-split">
		<div class="split-image">
		  <img src="@/assets/images/receiving_dunnage-rack.png" alt="Dunnage Stand">
		</div>
		<div class="split-content">
		  <h2>Dunnage Rack</h2>
		  <p>Store food & beverage shipments twelve inches off the floor, prior to organizing ingredients in storage containers.</p>
		  <a href="https://www.cambro.com/Products/shelving/dunnage-racks-/" target="_blank" class="learn-more">
		  <button @mouseover="isHovered = true" 
				  @mouseleave="isHovered = false"
				  :class="{ 'hovered': isHovered }">
			Learn More
		  </button></a>
		</div>
 	</div>
	 
	 <div class="static-testimonial">
		 <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		 <div class="testimonial-quote">Overall, these shelving units helped organize, condense, and protect our product, which is more than anyone can ask for.&quot;</div>
		 <div class="testimonial-pic"><img src="@/assets/images/receiving_testimonial-1.png" /></div>
		 <div class="testimonial-name"><a href="https://blog.cambro.com/2018/05/30/before-after-the-install-shorebreak-hotel-beverage-cooler/" target="_blank">Eric Zuniga, Food & Beverage Manager at the Kimpton Shorebreak Hotel</a></div>
	 </div>
	 
	<div class="static-split red-line">
		<div class="split-image">
		  <img src="@/assets/images/receiving_sanitizer.png" alt="Sanitizer shaker">
		</div>
		<div class="split-content">
		  <h2>"Sanitizer Only"<br>Personalized Shakers</h2>
		  <p>Store clean temperature measuring devices in personalized “Sanitizer Only” Shakers at the Receiving area.</p>
		  <a href="https://www.cambro.com/personalize" target="_blank" class="learn-more">
		  <button @mouseover="isHovered = true" 
				  @mouseleave="isHovered = false"
				  :class="{ 'hovered': isHovered }">
			Learn More
		  </button></a>
		</div>
	 </div>
	 
	 <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/receiving_service-cart.png" alt="Service Cart Pro">
		 </div>
		 <div class="split-content">
		   <h2>Service Cart PRO </h2>
		   <h3>(Large & Medium Sizes)</h3>
		   <p>Transport up to 600 lbs. of ingredients into temperature control as soon as possible.</p>
		   <a href="https://www.cambro.com/Products/food-and-salad-bars/service-and-utility-carts/service-cart-pro/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
	  </div>
	  
	  <div class="sub-white">
		<h1>Other Resources</h1>
		<div class="home-categories">
			<a href="#" class="home-cat dimmed"><div class="cat-receiving"></div></a>
			<a href="https://cambro.com/storesafe/storage" target="_parent" class="home-cat"><div class="cat-storage"></div></a>
			<a href="https://cambro.com/storesafe/preparation" target="_parent" class="home-cat"><div class="cat-prep"></div></a>
			<a href="https://cambro.com/storesafe/service" target="_parent" class="home-cat"><div class="cat-service"></div></a>
			<a href="https://cambro.com/storesafe/warewashing" target="_parent" class="home-cat"><div class="cat-warewashing"></div></a>
		</div>
	  </div>
	  
  	</div>


</template>

