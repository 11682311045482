<!-- components/Footer.vue -->
<template>
  <footer>
	<div class="footer-top">
	  <nav class="footer-column-1">
		<ul class="footer-list">
		  <li v-for="(link, index) in column1Links" :key="index">
			<a class="footer-link" :href="link.url" :target="link.external ? '_blank' : ''">
			  {{ link.text }}
			</a>
		  </li>
		</ul>
	  </nav>

	  <nav class="footer-column-2">
		<ul class="footer-list">
		  <li v-for="(link, index) in column2Links" :key="index">
			<img v-if="link.icon" :src="link.icon" :alt="link.iconAlt" class="w-8 h-4 mb-2">
			<a class="footer-link" 
			   :href="link.url" 
			   :target="link.external ? '_blank' : ''"
			   :id="link.id"
			   @click="link.onClick">
			  {{ link.text }}
			</a>
		  </li>
		</ul>
	  </nav>
	  
	  <nav class="footer-column-3">
		  <ul class="footer-list">
			<li v-for="(link, index) in column3Links" :key="index">
			  <img v-if="link.icon" :src="link.icon" :alt="link.iconAlt" class="w-8 h-4 mb-2">
			  <a class="footer-link" 
				 :href="link.url" 
				 :target="link.external ? '_blank' : ''"
				 :id="link.id"
				 @click="link.onClick">
				{{ link.text }}
			  </a>
			</li>
		  </ul>
		</nav>

	  <nav class="footer-social">
		<ul class="footer-social-links">
			<li>
				<a class="footer-social-icon" href="https://www.instagram.com/cambromfg/" target="_blank">
					<img src="@/assets/images/social-instagram.png" 
						alt="Cambro Instagram" 
						class="footer-social-icon">
				</a>
			</li>
			<li>
				<a class="footer-social-icon" href="http://www.linkedin.com/company/cambro-manufacturing" target="_blank">
					<img src="@/assets/images/social-linkedin.png" 
					alt="Cambro LinkedIn" 
					class="footer-social-icon">
	  			</a>
			</li>
			<li>
				<a class="footer-social-icon" href="https://twitter.com/Cambro1/" target="_blank">
					<img src="@/assets/images/social-x.png" 
					alt="Cambro on X" 
					class="footer-social-icon">
				  </a>
			</li>
			<li>
				<a class="footer-social-icon" href="https://www.youtube.com/user/iamcambro/" target="_blank">
					<img src="@/assets/images/social-youtube.png" 
					alt="Cambro on YouTube" 
					class="footer-social-icon">
				  </a>
			</li>
			<li>
				<a class="footer-social-icon" href="http://pinterest.com/CambroMfg" target="_blank">
					<img src="@/assets/images/social-pinterest.png" 
					alt="Cambro on Pinterest" 
					class="footer-social-icon">
				  </a>
			</li>
			<li>
				<a class="footer-social-icon" href="http://www.facebook.com/CambroMfg/" target="_blank">
					<img src="@/assets/images/social-facebook.png" 
					alt="Cambro Facebook" 
					class="footer-social-icon">
				  </a>
			</li>
			<li>
				<a class="footer-social-icon" href="http://tiktok.com/@cambromfg" target="_blank">
					<img src="@/assets/images/social-tiktok.png" 
					alt="Cambro Tik Tok" 
					class="footer-social-icon">
				  </a>
			</li>
			<li>
				<a class="footer-social-icon" href="https://cambroeats.com/" target="_blank">
					<img src="@/assets/images/social-podcast.png" 
					alt="CambroEats" 
					class="footer-social-icon">
				  </a>
			</li>
		</ul>
	  </nav>
	  <br class="cleaner" />
	</div>
	
	<div class="footer-bottom">
	  <p>© Cambro | Trusted Durability</p>
	</div>
  </footer>
</template>

<script setup>
import socialFacebook from '@/assets/images/social-facebook.png';
import socialInstagram from '@/assets/images/social-instagram.png';
import socialLinkedin from '@/assets/images/social-linkedin.png';
import socialPinterest from '@/assets/images/social-pinterest.png';
import socialTiktok from '@/assets/images/social-tiktok.png';
import socialX from '@/assets/images/social-x.png';
import socialYoutube from '@/assets/images/social-youtube.png';

defineOptions({
  name: 'Footer'
});
const column1Links = [
  { text: 'International Resource Centre', url: 'https://cambro.com/international-resource-centre/' },
  { text: 'Sustainability', url: 'https://cambro.com/sustainability/' },
  { text: 'Careers', url: 'https://careers.cambro.com/', external: true },
];

const column2Links = [
  { text: 'Contact Us', url: 'https://cambro.com/contact-us/' },
  { text: 'Privacy', url: 'https://cambro.com/privacy/' },
  { text: 'Terms', url: 'https://cambro.com/terms/' }
];

const column3Links = [
  { text: 'Warranty', url: 'https://cambro.com/warranty/' },
  { text: 'BPA Information', url: 'https://cambro.com/globalassets/page-content/footer/bisphenol-a-bpa-information-updated-11-29-23.pdf' },
  { text: 'California Supply Chain Act', url: 'https://assets.brandfolder.com/psnfly-8vmtdc-nxg7f/original/LEGAL - California Supply Chain Act Disclosure.pdf', external: true }
];

const socialLinks = [
  { name: 'Instagram', url: 'https://www.instagram.com/cambromfg/' },
  { name: 'LinkedIn', url: 'http://www.linkedin.com/company/cambro-manufacturing' },
  { name: 'X', url: 'https://twitter.com/Cambro1' },
  { name: 'YouTube', url: 'https://www.youtube.com/user/iamcambro' },
  { name: 'Pinterest', url: 'http://pinterest.com/CambroMfg' },
  { name: 'Facebook', url: 'http://www.facebook.com/CambroMfg' },
  { name: 'TikTok', url: 'http://tiktok.com/@cambromfg' },
  { name: 'CambroEats', url: 'https://cambroeats.com/' }
];
</script>