// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { setRouter } from '@/api';
import store from './store'
import html2pdf from 'html2pdf.js';
import WebFont from 'webfontloader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles.css';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/api';

const checkLocalStorageAccess = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

const storage = checkLocalStorageAccess() ? localStorage : {
  _data: {},
  setItem(id, val) { this._data[id] = val; },
  getItem(id) { return this._data[id]; },
  removeItem(id) { delete this._data[id]; }
};

// export default apiClient;

const isIframe = window.self !== window.top;
const basePath = isIframe ? '/storesafe-staging' : '/';

const app = createApp(App)
app.config.globalProperties.$html2pdf = html2pdf;

if (isIframe) {
  localStorage.setItem('basePath', '/storesafe-staging');
}

setRouter(router);

if (window.self !== window.top) {
  const basePath = '/storesafe-staging';
  localStorage.setItem('basePath', basePath);
  const lastRoute = localStorage.getItem('lastRoute');
  if (lastRoute) {
    router.push(basePath + lastRoute);
  }
}



WebFont.load({
  google: {
    families: ['Rajdhani:300,400,500,600,700']  // Include the weights you need
  }
});

app.use(router)
app.use(store)
app.mount('#app')