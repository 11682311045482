<template>
  <div class="assessment-results">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <button @click="continueAssessment" class="btn-continue exclude-from-pdf mobile">Continue This Assessment</button>
    <h1 class="assessment-title">{{ assessmentTitle }}</h1>
    
    <div class="assessment-meta">
      <div class="assessment-date">
        <strong>Started:</strong> {{ formatDate(assessment.createdAt) }}
      </div>
      <strong>Progress:</strong> <span class="completion-percent">{{ getCompletionPercentage }}</span>
      <div class="progress-bar">
        <div class="progress-fill" :style="{ width: `${calculateProgress}%` }"></div>
      </div>
    </div>
    
    <h2 style="text-align: center;width:100%;">Assessment Results</h2>
    <div class="total-score">
      <ResultsRing
        category="Total Score"
        :score="totalScore"
        :size="300"
        :weights="questionWeights"
      />
    </div>
    <p v-if="!isComplete">Note: This assessment is incomplete. Results are based on answered questions only.</p>
    <div class="results-grid">
      <ResultsRing
        v-for="category in categories"
        :key="category"
        :category="category"
        :score="getCategoryScore(category)"
        :weights="questionWeights"
      />
    </div>
    <div class="results-button-right"><button @click="viewDetailedReport" class="detail-button exclude-from-pdf">View Detailed Report</button></div>
    <div class="results-button-left"><button @click="downloadPDF" class="download-button exclude-from-pdf">Download Scores (PDF)</button></div>
  </div>
</template>

<script>
import ResultsRing from './ResultsRing.vue';
// import html2pdf from 'html2pdf.js';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { useRouter } from 'vue-router';

export default {
  components: {
    ResultsRing
  },
  props: {
    answers: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      required: true
    },
    currentQuestionIndex: {
      type: Number,
      required: true
    },
    assessmentTitle: {
      type: String,
      required: true
    },
    assessmentId: {  // Add this prop
      type: [String, Number],
      required: true
    },
    assessment: {
      type: Object,
      required: true
    }
  },
  setup() {
    const router = useRouter(); // Initialize router
    return { router };
  },
  data() {
    return {
      categories: ['Food Safety', 'Space Optimization', 'Cost Savings', 'Labor Efficiency', 'Sustainability'],
      userType: null
    };
  },
  created() {
    const hasRepWeights = this.questions.some(q => 
      q.weightFoodSafety !== undefined || 
      q.weightSpaceOptimization !== undefined || 
      q.weightCostSavings !== undefined ||
      q.weightLaborEfficiency !== undefined ||
      q.weightSustainability !== undefined
    );
    this.userType = hasRepWeights ? 'Rep' : 'Customer';
  },
  computed: {
    isComplete() {
      return this.questions.every(q => this.answers[q.id] !== undefined);
    },
    totalScore() {
      const scores = this.categories.map(category => this.getCategoryScore(category));
      return scores.reduce((a, b) => a + b, 0) / this.categories.length;
    },
    questionWeights() {
      return this.questions.reduce((acc, question) => {
        this.categories.forEach(category => {
          const weightKey = `weight${category.replace(/\s+/g, '')}`;
          acc[weightKey] = (acc[weightKey] || 0) + (question[weightKey] || 0);
        });
        return acc;
      }, {});
    },
    calculateProgress() {
      if (!this.questions || !this.answers) return 0;
      const answeredQuestions = Object.keys(this.answers).length;
      return Math.round((answeredQuestions / this.questions.length) * 100);
    },
    getCompletionPercentage() {
      return `${this.calculateProgress}% Complete`;
    }
  },
methods: {
      viewDetailedReport() {
        if (this.assessmentId) {
          this.router.push(`/assessment/${this.assessmentId}/report`);
        } else {
          console.error('Assessment ID is undefined');
        }
      },
      
      getCategoryScore(category) {
        // Your existing getCategoryScore method
      },
      
      formatDate(dateString) {
        // Your existing formatDate method
      },
      
      async downloadPDF() {
        try {
          const element = document.querySelector('.assessment-results');
          
          // Add a class to optimize for export
          element.classList.add('pdf-export-mode');
          
          // Hide elements that shouldn't be in the PDF
          const excludeFromPdfElements = element.querySelectorAll('.exclude-from-pdf');
          excludeFromPdfElements.forEach(el => {
            el.dataset.originalDisplay = el.style.display;
            el.style.display = 'none';
          });
          
          // Convert to image
          const dataUrl = await htmlToImage.toPng(element, {
            quality: 1.0,
            pixelRatio: 2,
            fontEmbedCSS: document.querySelector('style')?.textContent || '',
            filter: node => {
              // Don't render buttons or other elements that shouldn't be in the PDF
              return !node.classList?.contains('exclude-from-pdf');
            }
          });
          
          // Calculate dimensions to fit on A4
          const pdfWidth = 210;  // A4 width in mm
          const pdfHeight = 297; // A4 height in mm
          const aspectRatio = element.offsetHeight / element.offsetWidth;
          let imgWidth = pdfWidth - 20; // Margins
          let imgHeight = imgWidth * aspectRatio;
          
          // If height is too large, scale down
          if (imgHeight > pdfHeight - 20) {
            imgHeight = pdfHeight - 20;
            imgWidth = imgHeight / aspectRatio;
          }
          
          // Create PDF
          const pdf = new jsPDF({
            orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
            unit: 'mm',
            format: 'a4'
          });
          
          // Add the image to PDF
          pdf.addImage(
            dataUrl, 
            'PNG', 
            (pdfWidth - imgWidth) / 2, // Center horizontally
            10, // Top margin
            imgWidth, 
            imgHeight
          );
          
          // Save the PDF
          pdf.save(`${this.assessmentTitle}_scores.pdf`);
          
          // Restore original state
          element.classList.remove('pdf-export-mode');
          excludeFromPdfElements.forEach(el => {
            el.style.display = el.dataset.originalDisplay || '';
          });
          
        } catch (error) {
          console.error('Error generating PDF:', error);
          
          // Fallback to simple html2pdf if html-to-image fails
          const opt = {
            margin: 10,
            filename: `${this.assessmentTitle}_scores.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          };
          
          html2pdf().set(opt).from(element).save();
        }
      },
      
      continueAssessment() {
        this.$emit('continue-assessment');
      }
  }
};
</script>