<template>
  <div class="static warewashing">
	<div class="logo-left">
		<a href="https://www.cambro.com/storesafe" target="_parent">
			<img src="@/assets/images/storesafe-logo.png" width="100%" alt="StoreSafe logo" />
		</a>
	</div>
	<h1>Warewashing</h1>
  </div>

  <div class="static-grey">
	  When equipment, dinnerware, and glassware aren’t properly cleaned and sanitized, it can contaminate food as the process begins again. When warewashing, it’s important to have procedures for cleaning, air drying, and storing, and the equipment to support.
	</div>
	<div class="static-white">
		
	  <div class="static-split">
		  <div class="split-image">
			<img src="@/assets/images/warewashing_sink-station.jpg" alt="Dish Caddy" class="rounded">
		  </div>
		  <div class="split-content">
			<h2>Camshelving Elevation Series Wall Shelving Sink Station</h2>
			<p>Store cleaning supplies and air-dry tools within reach. Rust-resistant materials and molded-in CamGuard technology offer durability, hygiene, and convenience.</p>
			<a href="https://www.cambro.com/Products/warewashing/dish-caddies/compact-adjustable-dish-caddy-s-series/" target="_blank" class="learn-more">
			<button @mouseover="isHovered = true" 
					@mouseleave="isHovered = false"
					:class="{ 'hovered': isHovered }">
			  Learn More
			</button></a>
		  </div>
	   </div>
	   
	   <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/warewashing_camrack.jpg" alt="Camrack w/ Camdolly">
		 </div>
		 <div class="split-content">
		   <h2>Camrack w/ Camdolly</h2>
		   <p>The Camrack is designed with closed outside walls to protect delicate glassware and dinnerware from contamination during drying, storage and transport. Added Camdollies lift contents off the ground, and Camcovers seal open walls on top.</p>
		   <a href="https://www.cambro.com/Products/warewashing/camracks/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
	  </div>
	  
	  <div class="static-testimonial">
		 <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		 <div class="testimonial-quote">They go through the dishwasher perfectly well, they last a very long time, and they don’t degrade. The way that the water jets up into the glassware as it goes through, it cleans all parts of it… Glasses are clean; they’re typically ready for use immediately.&quot;</div>
		 <div class="testimonial-pic"><img src="@/assets/images/warewashing_testimonial-2.jpg" /></div>
		 <div class="testimonial-name"><a href="https://blog.cambro.com/2022/10/17/camracks-the-best-way-to-protect-store-and-transport-your-glassware/" target="_blank">Simon Harris, Director of Food and Beverage at Rancho Valencia</a></div>
	 </div>
		 
	   <div class="static-split red-line">
			<div class="split-image">
			  <img src="@/assets/images/warewashing_camshelving.jpg" alt="Camshelving Elements">
			</div>
			<div class="split-content">
			  <h2>Camshelving Elements XTRA Series Vertical Drying Rack</h2>
			  <p>This mobile rack employs removable 7-Slot Drying Cradles to safely hold and promote air circulation for faster and complete drying of trays, lids, sheet pans, and more.</p>
			  <a href="https://www.cambro.com/Industries/HC/healthcare-drying-racks/camshelving-elements-series-vertical-drying-rack/" target="_blank" class="learn-more">
			  <button @mouseover="isHovered = true" 
					  @mouseleave="isHovered = false"
					  :class="{ 'hovered': isHovered }">
				Learn More
			  </button></a>
			</div>
		 </div>
	   
	  <div class="static-split red-line">
		  <div class="split-image">
			<img src="@/assets/images/warewashing_dish-caddy.jpg" alt="Dish Caddy">
		  </div>
		  <div class="split-content">
			<h2>Dish Caddy</h2>
			<p>The extremely durable construction, holding 45 to 60 dishes per stack, protect delicate dinnerware within. The included vinyl cover protects dinnerware outside contaminants in storage and transportation applications.</p>
			<a href="https://www.cambro.com/Products/warewashing/dish-caddies/compact-adjustable-dish-caddy-s-series/" target="_blank" class="learn-more">
			<button @mouseover="isHovered = true" 
					@mouseleave="isHovered = false"
					:class="{ 'hovered': isHovered }">
			  Learn More
			</button></a>
		  </div>
	   </div>
	   
	   <div class="static-testimonial">
		   <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		   <div class="testimonial-quote">Cambro did it again by keeping the essential versatility of the configuration aspect of the dish caddy but made it smaller and amazingly easy to maneuver, making it a must for any culinary operation.&quot;</div>
		   <div class="testimonial-pic"><img src="@/assets/images/warewashing_testimonial-1.png" /></div>
		   <div class="testimonial-name"><a href="https://blog.cambro.com/2019/05/10/cambro-manufacturing-debuts-compact-adjustable-dish-caddy-s-series/" target="_blank">Ben LaFleche, Executive Chef at the Hilton Hotel in Huntington Beach, California</a></div>
	   </div>
		
		<div class="sub-white">
			<h1>Other Resources</h1>
			<div class="home-categories">
				<a href="https://cambro.com/storesafe/receiving" target="_parent" class="home-cat"><div class="cat-receiving"></div></a>
				<a href="https://cambro.com/storesafe/storage" target="_parent" class="home-cat"><div class="cat-storage"></div></a>
				<a href="https://cambro.com/storesafe/preparation" target="_parent" class="home-cat"><div class="cat-prep"></div></a>
				<a href="https://cambro.com/storesafe/service" target="_parent" class="home-cat"><div class="cat-service"></div></a>
				<a href="#" class="home-cat dimmed"><div class="cat-warewashing"></div></a>
			</div>
		</div>
	</div>
  
  
  </template>
  
