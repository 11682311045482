<template>
  <div class="admin-dashboard">
    <h1>Admin Dashboard</h1>
    <div class="sorting-controls">
      <label for="sort-select">Sort by: </label>
      <select id="sort-select" v-model="sortBy">
        <option value="alphabetical">Alphabetical</option>
        <option value="newest">Newest Account</option>
        <option value="recent">Most Recent Assessment</option>
        <option value="customer">Customer Assessments Only</option>
        <option value="rep">Rep Assessments Only</option>
      </select>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else class="admin-dash-assessments">
      <div v-for="user in sortedUsers" :key="user.id" class="user-card">
        <h3 class="admin-name">{{ user.name }}</h3>
        <div class="admin-email"><a :href="'mailto:' + user.email">{{ user.email }}</a></div>
        <h5 class="admin-company">Company: <div class="admin-text">{{ user.company }}</div></h5>
        <h5 class="admin-industry">Industry: <div class="admin-text">{{ user.industry }}</div></h5>
        <h5 class="admin-created">Account Created: <div class="admin-text">{{ formatDate(user.createdAt) }}</div></h5>
        <div class="cleaner"></div>
        <div class="admin-assessments">Assessments:</div>
        <ul class="admin-list">
          <!-- Rep Assessments -->
          <template v-for="assessment in user.assessments" :key="'rep-'+assessment.id">
            <li class="assessment-item rep-assessment">
              <button @click="viewAssessment(assessment.id, true)" class="view-btn">View</button>
              {{ assessment.title }}
              <div class="cleaner"></div>
              <div class="assessment-tag rep">Rep</div>
              <span class="admin-date">Started: {{ formatDate(assessment.createdAt) }}</span>
            </li>
          </template>
          <!-- Customer Assessments -->
          <template v-for="assessment in user.customerAssessments" :key="'cust-'+assessment.id">
            <li class="assessment-item customer-assessment">
              <button @click="viewAssessment(assessment.id, false)" class="view-btn">View</button>
              {{ assessment.title }}
              <div class="cleaner"></div>
              <div class="assessment-tag customer">Customer</div>
              <span class="admin-date">Started: {{ formatDate(assessment.createdAt) }}</span>
            </li>
          </template>
        </ul>
        <div class="cleaner"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
  name: 'AdminDashboard',
  setup() {
    const router = useRouter();
    const users = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const sortBy = ref('alphabetical');

    const sortedUsers = computed(() => {
      if (!users.value) return [];
      
      let filteredUsers = [...users.value];

      // First apply assessment type filter
      if (sortBy.value === 'customer') {
        filteredUsers = filteredUsers.filter(user => user.customerAssessments?.length > 0);
      } else if (sortBy.value === 'rep') {
        filteredUsers = filteredUsers.filter(user => user.assessments?.length > 0);
      }

      // Then apply sorting
      if (sortBy.value === 'alphabetical') {
        return filteredUsers.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      } else if (sortBy.value === 'newest') {
        return filteredUsers.sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0));
      } else if (sortBy.value === 'recent') {
        return filteredUsers.sort((a, b) => {
          const getAllAssessments = (user) => [
            ...(user.assessments || []),
            ...(user.customerAssessments || [])
          ];
          const latestA = getAllAssessments(a).length ? Math.max(...getAllAssessments(a).map(assessment => new Date(assessment.createdAt || 0))) : 0;
          const latestB = getAllAssessments(b).length ? Math.max(...getAllAssessments(b).map(assessment => new Date(assessment.createdAt || 0))) : 0;
          return latestB - latestA;
        });
      }
      return filteredUsers;
    });

    const fetchAdminData = async () => {
      try {
        loading.value = true;
        const response = await axios({
          method: 'get',
          url: 'https://storesafe.fyi/api/admin/users-with-assessments',
          withCredentials: true
        });
        
        console.log('Admin data response:', response.data);
        
        if (response.data && Array.isArray(response.data)) {
          users.value = response.data;
        } else {
          error.value = 'Invalid data format received from server';
        }
      } catch (err) {
        console.error('Admin data error:', err);
        error.value = err.response?.data?.message || 'Error fetching admin data';
      } finally {
        loading.value = false;
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    const viewAssessment = (id, isCustomer) => {
      router.push({
        path: `/results/${id}`,
        query: { 
          type: isCustomer ? 'customer' : 'rep',
          adminView: true,
          userId: String(users.value.find(u => 
            u.assessments?.some(a => a.id === id) || 
            u.customerAssessments?.some(a => a.id === id)
          )?.id || '')
        }
      });
    };
 
    onMounted(fetchAdminData);

    return {
      users,
      loading,
      error,
      sortBy,
      sortedUsers,
      formatDate,
      viewAssessment
    };
  }
};
</script>