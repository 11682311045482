<template>
  <div class="static storage">
	<div class="logo-left">
		<a href="https://www.cambro.com/storesafe" target="_parent">
			<img src="@/assets/images/storesafe-logo.png" width="100%" alt="StoreSafe logo" />
		</a>
	</div>
	<h1>Storage</h1>
  </div>

  	<div class="static-grey">
	  The shelving you store food on and containers you store food in are critical components of controlling food safety risks like cross-contamination, allergen cross-contact, and spoilage. In storage environments, it’s important to get food out of cardboard boxes and into reusable, food-safe containers that are date marked. Additionally, store containers on rust-free, cleanable shelving solutions.
	</div>
	<div class="static-white">
	  <div class="static-split">
		  <div class="split-image">
			<img src="@/assets/images/storage_camshelving.jpg" alt="Camshelving">
		  </div>
		  <div class="split-content">
			<h2>Camshelving Elements XTRA Series, Premium Series, and Basics Plus</h2>
			<p>Designed with food safety in mind, Camshelving will NEVER contaminate your food from rust or corrosion. This easy to clean storage solution has molded in CamGuard, an anti-microbial technology that helps to inhibit the growth of mold, fungus, and bacteria.</p>
			<a href="https://www.cambro.com/Products/shelving/ " target="_blank" class="learn-more">
			<button @mouseover="isHovered = true" 
					@mouseleave="isHovered = false"
					:class="{ 'hovered': isHovered }">
			  Learn More
			</button></a>
		  </div>
	   </div>
	   
	   <div class="static-testimonial">
		   <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		   <div class="testimonial-quote">Before, I used wire shelving, like everyone, but it was impossible to clean and adjust. With Camshelving, you can easily lift off the plates on any shelf and clean it with a towel or run it through a dishwasher. To say Camshelving is a time and labor savings is an understatement!&quot;</div>
		   <div class="testimonial-pic"><img src="@/assets/images/storage_testimonial-matt-bickford.jpg" /></div>
		   <div class="testimonial-name"><a href="https://blog.cambro.com/2024/02/20/stop-ruining-your-food-storage-with-metal-shelving/" target="_blank">Matt Bickford, Culinary Director of South Lyndale Liquors in Minneapolis, Minnesota</a></div>
	   </div>
	   
	  <div class="static-split red-line">
		  <div class="split-image">
			<img src="@/assets/images/storage_camsquares.jpg" alt="CamSquares">
		  </div>
		  <div class="split-content">
			<h2>CamSquares FreshPro</h2>
			<p>Store food in clear containers that promote food safety from First In to First Out. Paired with the Easy Seal Cover, CamSquares FreshPro protect contents from cross-contamination while offering shelf-life extension of ingredients. Industry-first drain shelves help boost ingredient freshness by lifting items out of their juices.</p>
			<a href="https://www.cambro.com/Products/food-storage/square-food-storage-containers/freshpro/camsquares-freshpro-food-storage-containers--camwear/" target="_blank" class="learn-more">
			<button @mouseover="isHovered = true" 
					@mouseleave="isHovered = false"
					:class="{ 'hovered': isHovered }">
			  Learn More
			</button></a>
		  </div>
	   </div>
	   
	   <div class="static-testimonial">
		  <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		  <div class="testimonial-quote">The new CamSquares FreshPro Lids seal well to preserve freshness and quality, they’re easily stackable and they don’t get bent or lose shape in high temp machines.&quot;</div>
		  <div class="testimonial-pic"><img src="@/assets/images/storage_testimonial-2.jpg" /></div>
		  <div class="testimonial-name"><a href="https://blog.cambro.com/2023/11/14/what-people-really-think-about-our-camsquares-freshpro/" target="_blank">David Hollands, Executive Chef at the San Francisco Marriott Marquis</a></div>
	  </div>
	   
	   <div class="static-split red-line">
		   <div class="split-image">
			 <img src="@/assets/images/storage_camsquares-2.jpg" alt="CamSquares">
		   </div>
		   <div class="split-content">
			 <h2>CamSquares FreshPro ½ Qt and 1 Qt – Translucent</h2>
			 <p>These reusable, commercial grade containers aren’t only a more cost-efficient and sustainable alternative to single-use containers, they extend product freshness and prevent spills in your storage and prep areas.</p>
			 <a href="https://www.cambro.com/Products/food-storage/square-food-storage-containers/freshpro/camsquares-freshpro--qt-and-1-qt--translucent/" target="_blank" class="learn-more">
			 <button @mouseover="isHovered = true" 
					 @mouseleave="isHovered = false"
					 :class="{ 'hovered': isHovered }">
			   Learn More
			 </button></a>
		   </div>
		</div>
		
		<div class="static-testimonial">
		  <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		  <div class="testimonial-quote">Working in kitchens for so long as I have, I’ve used plastic wrap and disposable cheap deli containers that break constantly… But then I heard about the Minis. Once we heard about that, I think that’s the final nail in the coffin for us with this expansion.&quot;</div>
		  <div class="testimonial-pic"><img src="@/assets/images/storage_testimonial-3.jpg" /></div>
		  <div class="testimonial-name"><a href="https://blog.cambro.com/2023/12/13/how-to-eliminate-these-single-use-plastics-in-your-kitchen/" target="_blank">Chef Rob Rubba, Owner of Oyster Oyster</a></div>
	  	</div>
		  
	   <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/storage_camwear.jpg" alt="Camwear Boxes with sliding lids">
		 </div>
		 <div class="split-content">
		   <h2>Camwear Boxes w/ Sliding or Seal Lids</h2>
		   <p>Replace those dusty cardboard boxes with crystal clear Camwear Boxes & Sliding Lids, allowing for safe food storing, easy inventory, and minimal handling from storage to prep.</p>
		   <a href="https://www.cambro.com/Products/food-storage/food-boxes/camwear-boxes/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
	    </div>
	  
		<div class="static-testimonial">
		  <div class="quote-top"><img src="@/assets/images/quote-top.jpg" /></div>
		  <div class="testimonial-quote">At this moment, all our food boxes are from Cambro. After covering the container with a lid, it plays the role of preserving the freshness of the content. The food pans will not touch the copper pipe of the refrigerator or the inside of the refrigerator, which will reduce the corrosion of our fruits. [Camwear Food Pans] also show our guests that the equipment we use is safer, more hygienic and we are more assured that the company only needs to consider a one-time investment.&quot;</div>
		  <!-- <div class="testimonial-pic"><img src="@/assets/images/storage_testimonial-3.jpg" /></div> -->
		  <div class="testimonial-name"><a href="https://blog.cambro.com/2022/04/25/restaurant-keeps-fruit-fresh-for-up-to-10-days-with-cambro-seal-covers/" target="_blank">Xuanjia Wang, CoCo Fresh Tea &amp; Juice at Suzhou Fenglong City Center</a></div>
	    </div>
		
	   <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/storage_camsquares-3.jpg" alt="CamSquares">
		 </div>
		 <div class="split-content">
		   <h2>CamSquares Allergen-Free w/ Seal Covers</h2>
		   <p>Isolate allergen-free foods from The Big Nine with easily identifiable containers that assist your staff in carrying out their food allergy procedures.</p>
		   <a href="https://www.cambro.com/Products/food-storage/square-food-storage-containers/camsquares-classic-food-storage-containers/camsquares-allergen-free/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
		</div>
		
	   <div class="static-split red-line">
		 <div class="split-image">
		   <img src="@/assets/images/storage_storesafe.jpg" alt="StoreSAfe Food Rotation Labels">
		 </div>
		 <div class="split-content">
		   <h2>StoreSafe Food Rotation Labels</h2>
		   <p>While common tape leaves behind sticky residue that harbors bacteria and increases cross-contamination risk, Cambro’s commercial grade StoreSafe labels are biodegradable, dissolve in the dishwasher, and make identifying and relabeling containers a breeze.</p>
		   <a href="https://www.cambro.com/Products/food-storage/storage-labels/storesafe-food-rotation-labels/" target="_blank" class="learn-more">
		   <button @mouseover="isHovered = true" 
				   @mouseleave="isHovered = false"
				   :class="{ 'hovered': isHovered }">
			 Learn More
		   </button></a>
		 </div>
		</div>
		
		<div class="sub-white">
			<h1>Other Resources</h1>
			<div class="home-categories">
				<a href="https://cambro.com/storesafe/receiving" target="_parent" class="home-cat"><div class="cat-receiving"></div></a>
				<a href="#" class="home-cat dimmed"><div class="cat-storage"></div></a>
				<a href="https://cambro.com/storesafe/preparation" target="_parent" class="home-cat"><div class="cat-prep"></div></a>
				<a href="https://cambro.com/storesafe/service" target="_parent" class="home-cat"><div class="cat-service"></div></a>
				<a href="https://cambro.com/storesafe/warewashing" target="_parent" class="home-cat"><div class="cat-warewashing"></div></a>
			</div>
		</div>
	  
	</div>


</template>

